import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import { Box, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Container } from "../components/utils"
import NewsList from "../components/newsList"
import FeaturedNews from "../components/featuredNews"
import Pagination from "react-js-pagination"
import DesignTokens from "../components/designTokens"
import { LanguageContext } from "../context/langContext"
import { getBlogPath } from "../utils/path"

const BlogListPage = ({ data: { page, site }, pageContext }) => {
  const locale = pageContext.locale
  const localeSuffix = locale === "it" ? "" : "/en"
  const articles = page.nodes.filter(name => name.slug !== null)
  const featured = articles.slice(0, 1)
  const others = articles.slice(1)
  const getLink = pageNumber => {
    if (pageNumber === 1 || pageNumber === 0) {
      return `${localeSuffix}/blog/`
    } else {
      return `${localeSuffix}/blog/${pageNumber}/`
    }
  }
  const handleChange = pageNumber => {
    if (pageNumber === 1) {
      navigate(`${localeSuffix}/blog/`)
    } else {
      navigate(`${localeSuffix}/blog/${pageNumber}/`)
    }
  }

  const i18nPaths =
    pageContext.currentPage === 1
      ? site.locales.map(locale => {
          return {
            locale: locale,
            value: getBlogPath(locale),
          }
        })
      : []

  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <SEO title={pageContext.title} lang={locale} />
      <Header locale={locale} title={pageContext.title} theme="light" />
      <CustomContainer>
        {featured.length > 0 && (
          <FeaturedNews news={featured[0]} locale={locale} path="blog" />
        )}
        {others.length > 0 && (
          <NewsList news={others} locale={locale} path="blog" />
        )}
        {articles.length === 0 && (
          <LanguageContext.Consumer>
            {t => (
              <Box sx={{ minHeight: "30rem", textAlign: "center" }}>
                <Text as="p" sx={{ fontSize: 5 }}>
                  {t.noArticles}
                </Text>
              </Box>
            )}
          </LanguageContext.Consumer>
        )}
        {/* {articles.length > pageContext.numStoriesPages && (
          <Pagination
            hideDisabled
            hideNavigation
            activePage={pageContext.currentPage}
            itemsCountPerPage={pageContext.limit}
            totalItemsCount={pageContext.numStoriesPages * pageContext.limit}
            pageRangeDisplayed={5}
            onChange={handleChange}
            getPageUrl={getLink}
          />
        )} */}
      </CustomContainer>
    </Layout>
  )
}

export default BlogListPage

const CustomContainer = styled(Container)`
  .pagination {
    margin: 0 0 3rem;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > li {
      margin: 0 1rem;
      &.active {
        a {
          text-decoration: none;
          color: ${DesignTokens.colors.black};
          cursor: auto;
        }
      }
    }
  }
`

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: allDatoCmsBlog(
      filter: { locale: { eq: $locale } }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        id
        title
        slug
        text
        originalPublishedAt
        meta {
          firstPublishedAt
        }
        _allSlugLocales {
          value
          locale
        }
        heroImage {
          sizes(imgixParams: { fit: "crop", w: "350", h: "260" }) {
            base64
            width
            height
            src
            srcSet
            sizes
          }
          alt
          url
        }
      }
    }
  }
`
